function setHeightNavProduct() {
  const banner = $("#banner");
  if (banner && banner.length > 0) {
    const heightBanner = banner.height();
    $(".list__category").css({
      height: heightBanner + "px"
    });
  } else {
    $(".list__category").css({
      display: "none"
    });
    $(".list__category").addClass("bg-blue");
  }
  $(".navbar-brand").on("click", () => {
    $(".list__category").toggle("slow");
  });
}

function change_alias(alias) {
  var str = alias;
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ  |ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ  |ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$|_/g,
    "-"
  );
  /* tìm và thay thế các kí tự đặc biệt trong chuỗi sang kí tự - */
  str = str.replace(/-+-/g, "-"); //thay thế 2- thành 1-
  str = str.replace(/^\-+|\-+$/g, "");
  //cắt bỏ ký tự - ở đầu và cuối chuỗi
  return str;
}

function menuDropDown() {
  $(".button__drop_down").on("click", function(event) {
    var idElement = $(this)[0].getAttribute("id");
    var menuDropDown = $(".menu-dropdown[data-toggle=" + idElement + "]");
    menuDropDown.toggle("fast");
  });
}

function showPopupDetail(item) {
  $("body").css({
    height: "100%",
    margin: "0",
    overflow: "hidden"
  });
  let html = `
  <div id="popup-order" tabindex="-1">
        <div class="container-fluid p-0">
            <div class="row no-gutters">
                <div class="col-12 col-lg-7 order-2 order-lg-1">
                    <div class="card card-left h-100 border-0">
                        <div class="card-body">
                            <form id="order-now">
                                <div class="form-title">ĐẶT HÀNG NGAY</div>
                                <div class="input-group pb-4">
                                    <input type="text" name="hoten" class="form-control" placeholder="${
                                      item.lang == "en"
                                        ? "Full name"
                                        : "Họ và tên"
                                    }" required>
                                </div>
                                <div class="input-group pb-4">
                                    <input type="text" name="dienthoai" class="form-control" placeholder="${
                                      item.lang == "en"
                                        ? "Phone number"
                                        : "Số điện thoại"
                                    }" required>
                                </div>
                                <div class="input-group pb-4">
                                    <input type="email" name="email" class="form-control" placeholder="Email" required>
                                </div>
                                <div class="input-group pb-4">
                                    <input type="text" name="diachi" class="form-control" placeholder="${
                                      item.lang == "en" ? "Address" : "Địa chỉ"
                                    }" required>
                                </div>
                                <div class="form-group">
                                    <textarea class="form-control" name="noidung" placeholder="${
                                      item.lang == "en" ? "Content" : "Nội dung"
                                    }" rows="3" required></textarea>
                                </div>
                                <input type="hidden" name="id_sp" value="${
                                  item.id
                                }" >
                                <input type="hidden" name="tonggia" value="${
                                  item.gia_goc
                                }" >
                                <button type="submit" class="submit-order">${
                                  item.lang == "en" ? "ORDER NOW" : "ĐẶT NGAY"
                                }</button>
                            </form>
                            <div class="contact">
                                <div class="row">
                                    <div class="col-12 col-md-3">
                                        <img src="upload/hinhanh/${
                                          item.ma_qr
                                        }" alt="" class="w-100">
                                    </div>
                                    <div class="col-12 col-md-8 pt-3 pt-md-0 text-center text-md-left">
                                        <h5>LIÊN HỆ: 
                                          <span>
                                            ${item.hotline}${
    item.hl1 && item.hl1 != "" ? " - " + item.hl1 : ""
  }
                                          </span>
                                        </h5>
                                        <h6>${item.email}</h6>
                                        <h6>${item.diachi}</h6>
                                    </div>
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-5 order-1 order-lg-2">
                    <div class="card card-right h-100 border-0">
                        <div class="close-popup">
                            ✖
                        </div>
                        <div class="card-body d-flex flex-wrap align-items-center">
                            <div class="content-product">
                                <img class="img-product" src="${
                                  item.photo
                                }" alt="${item.tenkhongdau}">
                                <div class="title-category">
                                  ${item.parent_name}
                                </div>
                                <div class="title-product">
                                  ${item.ten}
                                </div>
                                ${
                                  item.gia && item.gia != ""
                                    ? `<div class="price">${item.gia} VNĐ</div>`
                                    : `<div class="price">${
                                        item.lang == "en"
                                          ? "Contact us"
                                          : "Liên hệ"
                                      }</div>`
                                }
                                
                                <div class="type-product">
                                  ${
                                    item.loai == "color"
                                      ? `<img src="./images/type-color.png" alt="">`
                                      : ""
                                  }
                                  ${
                                    item.loai == "mono"
                                      ? `<img src="./images/type-mono.png" alt="">`
                                      : ""
                                  }
                                </div>
                                <div class="description text-left">
                                  ${item.mota}
                                </div>
                                <a href="san-pham/${item.tenkhongdau}-${
    item.id
  }.html" class="btn-show-detail">
                                    ${
                                      item.lang == "en"
                                        ? "Show detail"
                                        : "Xem chi tiết"
                                    }
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
  `;
  $(".container_mid").append('<div class="overlay-body"></div>');
  $(".container_mid").append(html);
  $("#popup-order, .overlay-body").fadeIn(500, function() {
    if ($("#popup-order").height() > $(window).height()) {
      $("#popup-order").css({
        height: "95%"
      });
    }
  });

  $(".close-popup, .overlay-body").click(closePopup);
  $("#order-now").on("submit", submitOrder);
}

function submitOrder(e) {
  e.preventDefault();
  const queryString = $(this).serialize();
  const urlParams = new URLSearchParams(queryString);
  const hoten = urlParams.get("hoten");
  const dienthoai = urlParams.get("dienthoai");
  const diachi = urlParams.get("diachi");
  const noidung = urlParams.get("noidung");
  const tonggia = urlParams.get("tonggia");
  const email = urlParams.get("email");
  const id = urlParams.get("id_sp");
  if (/(03|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(dienthoai)) {
    const data = {
      hoten: hoten,
      dienthoai: dienthoai,
      diachi: diachi,
      noidung: noidung,
      id_sp: id,
      tonggia: tonggia,
      email: email
    };
    $.ajax({
      data: data,
      type: "post",
      url: "ajax/insert_order.php",
      success: function(res) {
        closePopup();
        alert("Đặt hàng thành công");
      },
      error: function(err) {
        console.log(err);
      }
    });
  } else {
    alert("Sai định dạng số điện thoại");
  }
}

function closePopup() {
  $(".overlay-body").fadeOut(500);
  $("#popup-order").fadeOut(500, function() {
    $("body").attr("style", "");
    $("#popup-order, .overlay-body").remove();
  });
}

$(document).ready(function() {
  $("#banner").slick({
    lazyLoad: "ondemand",
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    cssEase: "linear",
    autoplay: true,
    autoplaySpeed: 5000
  });
  $(".list-category").slick({
    lazyLoad: "ondemand",
    dots: false,
    arrows: true,
    infinite: false,
    prevArrow: "<div class='icon-left'></div>",
    nextArrow: "<div class='icon-right'></div>",
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 415,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      }
    ]
  });

  $(".slider-detail").slick({
    lazyLoad: "ondemand",
    dots: false,
    arrows: true,
    infinite: false,
    prevArrow: "<div class='prev'><div class='icon-left'></div></div>",
    nextArrow: "<div class='next'><div class='icon-right'></div></div>",
    slidesToShow: 3,
    slidesToScroll: 3
  });

  $(".related-product").slick({
    lazyLoad: "ondemand",
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    prevArrow: "<div class='prev'><div class='icon-left'></div></div>",
    nextArrow: "<div class='next'><div class='icon-right'></div></div>",
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $(".related-product").slick("refresh");

  $(".hot-product").slick({
    lazyLoad: "ondemand",
    dots: false,
    arrows: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    prevArrow: "<div class='prev'><div class='icon-left'></div></div>",
    nextArrow: "<div class='next'><div class='icon-right'></div></div>",
    slidesToShow: 1,
    slidesToScroll: 1
  });

  setHeightNavProduct();
  menuDropDown();

  $("form#search").on("submit", function(e) {
    e.preventDefault();
    const keyword = document.getElementById("keyword").value;
    location.href = "tim-kiem.html/keyword=" + change_alias(keyword);
    loadPage(document.location);
  });

  $(".btn__show_info, .btn__order_now").on("click", function(e) {
    e.preventDefault();
    const id = $(this).data("id");
    if (id) {
      $.ajax({
        data: {
          id: id
        },
        type: "get",
        url: "ajax/get_detail_product.php",
        success: function(res) {
          const data = JSON.parse(res);
          showPopupDetail(data);
        },
        error: function(err) {
          console.log(err);
        }
      });
    }
  });

  $(".watch-video").click(function(e) {
    e.preventDefault();
    var link_viveo = $(this).attr("href");
    $("body").css({
      height: "100vh",
      overflow: "hidden"
    });
    $("body").append(
      '<div id="popup-video"><div class="content-popup"><div class="close-popup">✖</div><iframe id="dh-iframe" width="800px" height="500px" src="https://www.youtube.com/embed/' +
        link_viveo +
        '?rel=0" frameborder="0" allowfullscreen></iframe></div></div>'
    );
    $("#popup-video").fadeIn(500);

    $(document).click(function(e) {
      if (e.target !== $("#dh-iframe")[0]) {
        $("#popup-video").fadeOut(500, function() {
          $("body").attr("style", "");
          $("#popup-video").remove();
        });
      }
    });

    $(".close-popup").click(function() {
      $("#popup-video").fadeOut(500, function() {
        $("body").attr("style", "");
        $("#popup-video").remove();
      });
    });
    return false;
  });
  let scrollTop;
  const banner = $("#banner");
  $(window).scroll(function() {
    scrollTop = $(this).scrollTop();
    if (banner && banner.length > 0) {
      if (scrollTop > $(".header__top").height()) {
        $(".header__nav").addClass("fixed");
        $(".list__category").addClass("bg-blue");
        $(".list__category").css({
          display: "none"
        });
      } else {
        $(".header__nav").removeClass("fixed");
        $(".list__category").removeClass("bg-blue");
        $(".list__category").css({
          display: ""
        });
      }
    } else {
      if (scrollTop > $(".header__top").height()) {
        $(".header__nav").addClass("fixed");
      } else {
        $(".header__nav").removeClass("fixed");
      }
    }
  });
  function onSearchs() {
    var keyword = document.getElementById("keywords").value;
    if (keyword == "") alert("Bạn chưa nhập từ khóa!");
    else {
      //var encoded = Base64.encode(keyword);
      location.href = "tim-kiem.html/keyword=" + keyword;
    }
  }
  $("#tim-search").click(onSearchs);
});
